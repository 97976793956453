<template>
    <div class="main">
		<div class="wrapper">
			<div class="logo-wrapper">
				<v-img
					:src="require('../assets/logo_clock.svg')"
					contain
					width="100%"
				/>
			</div>

            <div class="content-wrapper">
                <div class="info-wrapper mb-8">
                    <h3>Password Reset Request Sent</h3>
					<br />
                    <p>A password reset message was sent to your email address if your account exists. 
                        Please click the link in that message to reset your password.</p>
                    <p>If you do not receive the password reset message within a few moments, 
                        please check your spam folder and other filtering tools.</p>
                </div>

                <router-link to="/" class="message--text">Go back</router-link>
            </div>

			<div class="logo-wrapper">
				<v-img
					:src="require('../assets/generic_logo.png')"
					contain
					width="100%"
				/>
			</div>
		</div>
	</div>
</template>

<script>

export default {

	name: 'SendLink',

	data: () => ({
	}),
    props: {
    },
    methods: {
		
    }
	};
</script>

<style scoped>

.main{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
}


.wrapper{
	width: 30%;
	margin-left: 35%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	flex-wrap: wrap;
}

.logo-wrapper{
	width: 80%;
	margin:40px 10%;
}

.content-wrapper{
	flex-basis: 100%;
	text-align: center;
}

.info-wrapper{
    text-align: start;
}

a {
	text-decoration: none;
	font-size: 14px;
}

@media only screen and (max-width: 1904px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 1264px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 960px) { 
	.wrapper {
		width: 50%;
		margin-left: 25%;
		transform: translate(0);
	}
}

@media only screen and (max-width: 600px) { 
	.wrapper {
		width: 70%;
		margin-left: 15%;
		transform: translate(0);
	}
}

</style>
